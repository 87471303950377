<template>
  <v-card-title>
    <v-btn
      v-if="!disabled"
      icon
      plain
      :outlined="selection.match && text === selection.instruction"
      :disabled="disabled"
      :color="selection.match && text === selection.instruction ? 'primary' : 'grey'"
      class="mb-1 mr-n1"
      @click="open = true"
      @mousedown.stop="!instructions.length ? fetchInstructions() : null"
    >
      <i class="fal fa-lightbulb" />
    </v-btn>
    <smart-rank
      v-if="selection.match && text === selection.instruction"
      :item="selection"
    />
    <v-navigation-drawer
      v-model="open"
      transition="slide-x-reverse-transition"
      :width="$vuetify.breakpoint.mobile ? '100%' : '43%'"
      app
      right
      temporary
    >
      <template #prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="text-h6"
              v-text="question"
            />
          </v-list-item-content>
          <v-list-item-action>
            <v-badge
              :value="hover"
              :content="new_threshold | percentage"
              transition="slide-x-transition"
              color="tertiary lighten-2"
              outlined
              overlap
              left
            >
              <v-hover v-model="hover">
                <v-btn
                  icon
                  :disabled="loading"
                  @click.stop="new_threshold > 0.05 ? new_threshold -= 0.05 : new_threshold = 0; fetchInstructions(false, new_threshold)"
                >
                  <v-icon small>
                    mdi-reload
                  </v-icon>
                </v-btn>
              </v-hover>
            </v-badge>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn
              icon
              tile
              @click.stop="open = false"
            >
              <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <smart-filters
          :items="instructions"
          :filter-gender="filterGender"
          @filtered="filteredItems = $event"
        />
        <v-divider />
      </template>
      <v-skeleton-loader
        :loading="loading && instructions.length < 1"
        type="instruction@6"
        :types="{
          instruction: 'list-item-avatar-three-line, divider'
        }"
        fade-transition
      >
        <v-list
          three-line
          dense
          class="pt-0"
        >
          <template
            v-for="instruction, id in filteredInstructions"
          >
            <v-list-item
              :key="`instruction:${id}`"
              :input-value="instruction.match === 1"
              :color="'primary lighten-' + (instruction.match < 1 ? Math.round((instruction.match - 1) * -5) : 0)"
              @click.stop="$emit('instruction-selected', instruction); selection = {}; open = false"
            >
              <v-list-item-icon class="mr-3">
                <smart-rank
                  :item="instruction"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <smart-factors
                  :item="instruction"
                  :organisms="organisms"
                  :resistance-genes="resistanceGenes"
                  :relevant-resistance="relevantResistance"
                  :diagnoses="diagnoses"
                  :allergies="allergies"
                  :gender="filterGender"
                />
                <div class="text-body-2">
                  <v-avatar
                    v-if="instruction.pediatric"
                    color="orange"
                    :size="20"
                    class="mt-n1 mr-1"
                  >
                    <v-icon small>
                      fas fa-child
                    </v-icon>
                  </v-avatar>
                  {{ typeof instruction.instruction === 'object' ? instruction.instruction.en : instruction.instruction }}
                </div>
              </v-list-item-content>
              <!-- <v-list-item-action>
                <v-btn
                  icon
                  x-small
                  @click.stop="hideInstruction(instruction)"
                >
                  <v-icon>fal fa-eye-slash</v-icon>
                </v-btn>
              </v-list-item-action> -->
            </v-list-item>
            <v-divider :key="`divider:${id}`" />
          </template>
          <v-list-item v-if="!filteredInstructions.length">
            <v-list-item-subtitle>No suggestions found</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-skeleton-loader>
    </v-navigation-drawer>
  </v-card-title>
</template>
<script>
  export default {
    components: {
      SmartRank: () => import('@/components/interpretation/SmartRank.vue'),
      SmartFilters: () => import('@/components/interpretation/SmartFilters.vue'),
      SmartFactors: () => import('@/components/interpretation/SmartFactors.vue'),
    },
    props: {
      disabled: Boolean,
      preload: Boolean,
      autoPopulate: {
        type: Boolean,
        default: false,
      },
      filterGender: {
        type: [Boolean, String],
        default: false,
      },
      question: {
        type: String,
        default: null,
      },
      field: {
        type: String,
        default: null,
      },
      uuid: {
        type: String,
        required: true,
      },
      organisms: {
        type: Array,
        default: () => ([]),
      },
      resistanceGenes: {
        type: Array,
        default: () => ([]),
      },
      relevantResistance: {
        type: Array,
        default: () => ([]),
      },
      diagnoses: {
        type: Array,
        default: () => ([]),
      },
      allergies: {
        type: Array,
        default: () => ([]),
      },
      threshold: {
        type: Number,
        default: null,
      },
      text: {
        type: String,
        default: null,
      },
      source: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        open: false,
        loading: false,
        instructions: [],
        selection: {},
        filter: {
          organisms: [],
          resistances: [],
          allergies: [],
          diagnoses: [],
          gender: [],
          pediatric: null,
        },
        filteredItems: null,
        new_threshold: this.threshold,
        hover: false,
      }
    },
    computed: {
      filteredInstructions () {
        return this.filteredItems ? this.filteredItems : this.instructions.slice(0, 25)
      },
    },
    watch: {
      uuid: function () {
        this.fetchInstructions()
        this.selection = {}
      },
      threshold: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.fetchInstructions(false)
        }
      },
    },
    created () {
      if (this.preload) {
        this.fetchInstructions()
      }
    },
    methods: {
      fetchInstructions (cached = true, threshold = this.threshold, once = false) {
        this.loading = true
        this.instructions = []
        this.axios.get('lab_results/' + this.uuid + '/interpretation/instructions', {
          params: {
            threshold: threshold,
            key: this.field,
            cache: cached,
          },
        })
          .then((response) => {
            if (!response.data.length && !once ) {
              this.new_threshold -= 0.05
              if (this.new_threshold > 0) {
                this.fetchInstructions(false, this.new_threshold, 500)
              }
            } else {
              this.instructions = Object.values(response.data)
              this.loading = false
              if (this.autoPopulate && !this.text && this.instructions[0] && this.instructions[0].match >= 0.9 && this.field != 'alternatives') {
                this.selection = this.instructions[0];
                this.$emit('instruction-selected', this.selection)
              }
            }
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      hideInstruction (instruction) {
        const index = this.instructions.indexOf(instruction)
        this.$dialog.confirm({
          text: 'No longer show this paragraph in the future?',
          title: 'Hide instruction paragraph',
          overlay: true,
        })
          .then((res) => {
            if (res) {
              this.axios.patch('lab_results/interpretation/instructions/' + instruction.id, { hidden_at: this.$moment() })
                .then(() => {
                  this.$delete(this.instructions, index)
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
      close () {
        this.$destroy();
        if (this.$el) {
          this.$el.parentNode.removeChild(this.$el);
        }
      },
    },
  }
</script>
<style scoped>
.text-body-2 {
  line-height: 1.1rem;
  word-break: break-word;
}
</style>
